
// 星合页面
const Home = () => import(/* webpackChunkName: 'index' */ '@/views/index/home.vue')
const HomeListArtist = () => import(/* webpackChunkName: 'index-list-artist' */ '@/views/index/list/artist.vue')
const HomeListAmbassador = () => import(/* webpackChunkName: 'index-list-ambassador' */ '@/views/index/list/ambassador.vue')
// 艺人页面
const Artist = () => import(/* webpackChunkName: 'artist' */ '@/views/artist/home.vue')
// common（other页）
const Search = () => import(/* webpackChunkName: 'search' */ '@/views/other/search.vue')
const Layouts = () => import(/* webpackChunkName: 'layouts' */ '@/layouts/page.vue')
const Detail = () => import(/* webpackChunkName: 'detail' */ '@/views/detail/page.vue')
const Error404 = () => import(/* webpackChunkName: 'error404' */ '@/views/other/404.vue')
// list 列表页
const ListHotspot = () => import(/* webpackChunkName: 'list-hotspot' */ '@/views/list/hotspot.vue')
const ListAmbassador = () => import(/* webpackChunkName: 'list-ambassador' */ '@/views/list/ambassador.vue')
const ListMovieTv = () => import(/* webpackChunkName: 'list-movietv' */ '@/views/list/movietv.vue')
const ListMusic = () => import(/* webpackChunkName: 'list-music' */ '@/views/list/music.vue')
const ListVariety = () => import(/* webpackChunkName: 'list-variety' */ '@/views/list/variety.vue')
const ListImageText = () => import(/* webpackChunkName: 'list-imageText' */ '@/views/list/imageText.vue')
// const ListCase = () => import(/* webpackChunkName: 'list-case' */ '@/views/list/case.vue')
// const ListNew = () => import(/* webpackChunkName: 'list-new' */ '@/views/list/new.vue')
// 东娱页面
const Dongyu = () => import(/* webpackChunkName: 'dongyu' */ '@/views/dongyu/home.vue')
const DongyuListArtist = () => import(/* webpackChunkName: 'dongyu-list-artist' */ '@/views/dongyu/list/artist.vue') //东娱艺人
const DongyuListActivity = () => import(/* webpackChunkName: 'dongyu-list-activity' */ '@/views/dongyu/list/activity.vue') //东娱活动（演唱会，综艺列表）
const DongyuListCase = () => import(/* webpackChunkName: 'dongyu-list-case' */ '@/views/dongyu/list/case.vue') //东娱案例
const DongyuListNew = () => import(/* webpackChunkName: 'dongyu-list-new' */ '@/views/dongyu/list/new.vue') //东娱资讯
const routes = [
    {
        name: "Error404",
        path: '/error404',
        component: Error404
    },
    {
        path: '/',
        name: "home",
        meta: { nav: "home" },
        component: Home,
    },
    {
        name: "Search",
        path: '/search',
        component: Search,
    },
    {
        path: '/home',
        meta: { nav: "home" },
        component: Layouts,
        children: [
            {
                name: "Home", path: '/home', component: Home,
            },
            {
                name: "HomeDetail",
                path: 'detail',
                component: Detail
            },
            {
                name: "HomeListArtist",
                path: 'list/artist',
                meta: { nav: "artist" },
                component: HomeListArtist
            },
            {
                name: "HomeListHotspot",
                path: 'list/hotspot',
                meta: { nav: "hotspot" },
                component: ListHotspot
            },
            {
                name: "HomeListAmbassador",
                path: 'list/ambassador',
                component: HomeListAmbassador
            },
            {
                name: "HomeListMovieTv",
                path: 'list/movietv',
                component: ListMovieTv
            },
            {
                name: "HomeListMusic",
                path: 'list/music',
                component: ListMusic
            },
            {
                name: "HomeListVariety",
                path: 'list/variety',
                component: ListVariety
            },
            // {
            //     name: "HomeListCase",
            //     path: 'list/case',
            //     meta: { nav: "case" },
            //     component: ListCase
            // },
            // {
            //     name: "HomeListNew",
            //     path: 'list/new',
            //     meta: { nav: "new" },
            //     component: ListNew
            // },
        ]
    },
    {
        path: '/dongyu',
        meta: { nav: "dongyu", title: "东娱传媒" },
        component: Layouts,
        children: [
            {
                name: 'Dongyu',
                path: '/dongyu',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: Dongyu
            },
            {
                name: "DongyuDetail",
                path: 'detail',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: Detail
            },
            {
                name: "DongyuListArtist",
                path: 'list/artist',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListArtist
            },
            {
                name: "DongyuListHotspot",
                path: 'list/hotspot',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListHotspot
            },
            {
                name: "DongyuListAmbassador",
                path: 'list/ambassador',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListAmbassador,
            },
            {
                name: "DongyuListMovieTv",
                path: 'list/movietv',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListMovieTv,
            },
            {
                name: "DongyuListMusic",
                path: 'list/music',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListMusic,
            },
            {
                name: "DongyuListVariety",
                path: 'list/variety',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: ListVariety,
            },
            {
                name: "DongyuListCase",
                path: 'list/case',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListCase,
            },
            {
                name: "DongyuListNew",
                path: 'list/new',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListNew
            },
            {
                name: "DongyuListActivity",
                path: 'list/activity',
                meta: { nav: "dongyu", title: "东娱传媒" },
                component: DongyuListActivity
            },
        ]
    },
    {
        path: '/:artist',
        meta: { nav: "artist" },
        component: Layouts,
        children: [
            {
                name: 'Artist',
                path: '/:artist',
                component: Artist,
                // meta: { keepAlive: true }
            },
            {
                name: "ArtistDetail",
                path: 'detail',
                component: Detail
            },
            {
                name: "ArtistListHotspot",
                path: 'list/hotspot',
                component: ListHotspot
            },
            {
                name: "ArtistListAmbassador",
                path: 'list/ambassador',
                component: ListAmbassador
            },
            {
                name: "ArtistListMovieTv",
                path: 'list/movietv',
                component: ListMovieTv
            },
            {
                name: "ArtistListMusic",
                path: 'list/music',
                component: ListMusic
            },
            {
                name: "ArtistListVariety",
                path: 'list/variety',
                component: ListVariety
            },
            // {
            //     name: "ArtistListCase",
            //     path: 'list/case',
            //     component: ListCase
            // },
            {
                name: "ArtistListImageText",
                path: 'list/imagetext',
                component: ListImageText
            },
        ]
    },
    {
        path: '*',
        component: () => Error404
    }
]

export default routes