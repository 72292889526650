
/* 
 * 公用方法
*/

import Vue from 'vue'

var utils = {
    // 设置连接符
    setUrlConnector(url) {
      let connector = "?";
      if (url.indexOf("?") > -1) {
        connector = "&";
      }
      return connector;
    },
    isMobileOrPc: function () {
        let terminal = utils.isMobile() ? "mobile" : "pc"
        return terminal
    },
    // 价格换算（分换算成元）
    convertFenToYuan: function (fen) {
        return fen ? fen / 100.0 : "";
    },

    // 获取网址链接里参数值
    GetQueryString: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },

    // 图片数组变成字符串
    imgArrToString: function (arr) {
        var str = "";
        if (arr.length > 0) {
            arr.forEach(v => {
                if (v.hasOwnProperty("response")) {
                    str += v.response.data + ",";
                } else if (v.hasOwnProperty("url")) {
                    str += v.url + ",";
                } else {
                    str += v + ",";
                }
            });
            // 去除字符串最后一个逗号
            str = str.substr(0, str.length - 1);
        }
        return str;
    },

    // 判断是否手机访问
    isMobile: function () {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|MicroMessenger)/i
        );
        return flag;
    },
    // 判断是否安卓手机
    isAndroid: function () {
        // var u = navigator.userAgent;
        // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
        // if (isAndroid) {}
        let flag = navigator.userAgent.match(
            /(Android|Adr)/i
        );
        return flag;
    },

    // 判断是否为null 不为空为false  null为true
    isEmpty: function (res) {
        if (res == "" || typeof (res) == undefined || res == null || Object.keys(res).length === 0) {
            return true;
        } else {
            return false;
        }
    },
    // 滚动到顶部
    scrollToTop: function () {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    },
    /**
     * 复制
     * @param {*} value 
     */
    copyText: function (value) {
        var textArea = document.createElement("textarea");
        textArea.style.background = 'transparent';
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            var successful = document.execCommand('copy');
        } catch (err) {
            console.log('复制失败，请手动复制');
        }
        document.body.removeChild(textArea);

    },


    /********************************************* 关于数组 ******************************/
    /**
    * @param  {arr} 数组
    * @param  {fn} 回调函数
    * @return {undefined}
    */
    each: function (arr, fn) {
        fn = fn || Function;
        var a = [];
        var args = Array.prototype.slice.call(arguments, 1);
        for (var i = 0; i < arr.length; i++) {
            var res = fn.apply(arr, [arr[i], i].concat(args));
            if (res != null) a.push(res);
        }
    },

    /**
     * @param  {arr} 数组
     * @param  {fn} 回调函数
     * @param  {thisObj} this指向
     * @return {Array} 
     */
    map: function (arr, fn, thisObj) {
        var scope = thisObj || window;
        var a = [];
        for (var i = 0, j = arr.length; i < j; ++i) {
            var res = fn.call(scope, arr[i], i, this);
            if (res != null) a.push(res);
        }
        return a;
    },

    /**
    * @param  {arr} 数组
    * @param  {type} 1：从小到大   2：从大到小   3：随机
    * @return {Array}
    */
    sort: function (arr, type = 1) {
        return arr.sort((a, b) => {
            switch (type) {
                case 1:
                    return a - b;
                case 2:
                    return b - a;
                case 3:
                    return Math.random() - 0.5;
                default:
                    return arr;
            }
        })
    },
    /**
     * 去重
     */
    unique: function (arr) {
        if (Array.hasOwnProperty('from')) {
            return Array.from(new Set(arr));
        } else {
            var n = {}, r = [];
            for (var i = 0; i < arr.length; i++) {
                if (!n[arr[i]]) {
                    n[arr[i]] = true;
                    r.push(arr[i]);
                }
            }
            return r;
        }
    },

    /**
    * 求两个集合的并集
    */
    union: function (a, b) {
        var newArr = a.concat(b);
        return this.unique(newArr);
    },

    /**
     * 求两个集合的交集
     */
    intersect(a, b) {
        var _this = this;
        a = this.unique(a);
        return this.map(a, function (o) {
            return _this.contains(b, o) ? o : null;
        });
    },

    /**
    * 删除其中一个元素
    */
    remove(arr, ele) {
        var index = arr.indexOf(ele);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    },

    /**
    * 将类数组转换为数组的方法
    */
    formArray: function (ary) {
        var arr = [];
        if (Array.isArray(ary)) {
            arr = ary;
        } else {
            arr = Array.prototype.slice.call(ary);
        };
        return arr;
    },

    /**
    * 最大值
    */
    max: function (arr) {
        return Math.max.apply(null, arr);
    },

    /**
    * 最小值
    */
    min: function (arr) {
        return Math.min.apply(null, arr);
    },

    /**
    * 求和
    */
    sum: function (arr) {
        return arr.reduce((pre, cur) => {
            return pre + cur
        })
    },

    /**
    * 平均值
    */
    average: function (arr) {
        return this.sum(arr) / arr.length
    },

    /**
    * 判断类型集合
    * @param {*} str 
    * @param {*} type 
    */
    checkStr: function (str, type) {
        switch (type) {
            case 'phone':   //手机号码
                return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
            case 'tel':     //座机
                return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
            case 'card':    //身份证
                return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
            case 'pwd':     //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
                return /^[a-zA-Z]\w{5,17}$/.test(str)
            case 'postal':  //邮政编码
                return /[1-9]\d{5}(?!\d)/.test(str);
            case 'QQ':      //QQ号
                return /^[1-9][0-9]{4,9}$/.test(str);
            case 'email':   //邮箱
                return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
            case 'money':   //金额(小数点2位)
                return /^\d*(?:\.\d{0,2})?$/.test(str);
            case 'URL':     //网址
                return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
            case 'IP':      //IP
                return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
            case 'date':    //日期时间
                return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
            case 'number':  //数字
                return /^[0-9]$/.test(str);
            case 'english': //英文
                return /^[a-zA-Z]+$/.test(str);
            case 'chinese': //中文
                return /^[\\u4E00-\\u9FA5]+$/.test(str);
            case 'lower':   //小写
                return /^[a-z]+$/.test(str);
            case 'upper':   //大写
                return /^[A-Z]+$/.test(str);
            case 'HTML':    //HTML标记
                return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
            default:
                return true;
        }
    },

    /**
     * 将阿拉伯数字翻译成中文的大写数字
     */
    numberToChinese: function (num) {
        var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
        var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
        var a = ("" + num).replace(/(^0*)/g, "").split("."),
            k = 0,
            re = "";
        for (var i = a[0].length - 1; i >= 0; i--) {
            switch (k) {
                case 0:
                    re = BB[7] + re;
                    break;
                case 4:
                    if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
                        .test(a[0]))
                        re = BB[4] + re;
                    break;
                case 8:
                    re = BB[5] + re;
                    BB[7] = BB[5];
                    k = 0;
                    break;
            }
            if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
                re = AA[0] + re;
            if (a[0].charAt(i) != 0)
                re = AA[a[0].charAt(i)] + BB[k % 4] + re;
            k++;
        }

        if (a.length > 1) // 加上小数部分(如果有小数部分)
        {
            re += BB[6];
            for (var i = 0; i < a[1].length; i++)
                re += AA[a[1].charAt(i)];
        }
        if (re == '一十')
            re = "十";
        if (re.match(/^一/) && re.length == 3)
            re = re.replace("一", "");
        return re;

    },
    /**
     * 将数字转换为大写金额
     */
    changeToChinese: function () {
        //判断如果传递进来的不是字符的话转换为字符
        if (typeof Num == "number") {
            Num = new String(Num);
        };
        Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
        Num = Num.replace(/ /g, "") //替换tomoney()中的空格
        Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
        if (isNaN(Num)) { //验证输入的字符是否为数字
            //alert("请检查小写金额是否正确");
            return "";
        };
        //字符处理完毕后开始转换，采用前后两部分分别转换
        var part = String(Num).split(".");
        var newchar = "";
        //小数点前进行转化
        for (var i = part[0].length - 1; i >= 0; i--) {
            if (part[0].length > 10) {
                return "";
                //若数量超过拾亿单位，提示
            }
            var tmpnewchar = ""
            var perchar = part[0].charAt(i);
            switch (perchar) {
                case "0":
                    tmpnewchar = "零" + tmpnewchar;
                    break;
                case "1":
                    tmpnewchar = "壹" + tmpnewchar;
                    break;
                case "2":
                    tmpnewchar = "贰" + tmpnewchar;
                    break;
                case "3":
                    tmpnewchar = "叁" + tmpnewchar;
                    break;
                case "4":
                    tmpnewchar = "肆" + tmpnewchar;
                    break;
                case "5":
                    tmpnewchar = "伍" + tmpnewchar;
                    break;
                case "6":
                    tmpnewchar = "陆" + tmpnewchar;
                    break;
                case "7":
                    tmpnewchar = "柒" + tmpnewchar;
                    break;
                case "8":
                    tmpnewchar = "捌" + tmpnewchar;
                    break;
                case "9":
                    tmpnewchar = "玖" + tmpnewchar;
                    break;
            }
            switch (part[0].length - i - 1) {
                case 0:
                    tmpnewchar = tmpnewchar + "元";
                    break;
                case 1:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
                    break;
                case 2:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
                    break;
                case 3:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
                    break;
                case 4:
                    tmpnewchar = tmpnewchar + "万";
                    break;
                case 5:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
                    break;
                case 6:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
                    break;
                case 7:
                    if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
                    break;
                case 8:
                    tmpnewchar = tmpnewchar + "亿";
                    break;
                case 9:
                    tmpnewchar = tmpnewchar + "拾";
                    break;
            }
            var newchar = tmpnewchar + newchar;
        }
        //小数点之后进行转化
        if (Num.indexOf(".") != -1) {
            if (part[1].length > 2) {
                // alert("小数点之后只能保留两位,系统将自动截断");
                part[1] = part[1].substr(0, 2)
            }
            for (i = 0; i < part[1].length; i++) {
                tmpnewchar = ""
                perchar = part[1].charAt(i)
                switch (perchar) {
                    case "0":
                        tmpnewchar = "零" + tmpnewchar;
                        break;
                    case "1":
                        tmpnewchar = "壹" + tmpnewchar;
                        break;
                    case "2":
                        tmpnewchar = "贰" + tmpnewchar;
                        break;
                    case "3":
                        tmpnewchar = "叁" + tmpnewchar;
                        break;
                    case "4":
                        tmpnewchar = "肆" + tmpnewchar;
                        break;
                    case "5":
                        tmpnewchar = "伍" + tmpnewchar;
                        break;
                    case "6":
                        tmpnewchar = "陆" + tmpnewchar;
                        break;
                    case "7":
                        tmpnewchar = "柒" + tmpnewchar;
                        break;
                    case "8":
                        tmpnewchar = "捌" + tmpnewchar;
                        break;
                    case "9":
                        tmpnewchar = "玖" + tmpnewchar;
                        break;
                }
                if (i == 0) tmpnewchar = tmpnewchar + "角";
                if (i == 1) tmpnewchar = tmpnewchar + "分";
                newchar = newchar + tmpnewchar;
            }
        }
        //替换所有无用汉字
        while (newchar.search("零零") != -1)
            newchar = newchar.replace("零零", "零");
        newchar = newchar.replace("零亿", "亿");
        newchar = newchar.replace("亿万", "亿");
        newchar = newchar.replace("零万", "万");
        newchar = newchar.replace("零元", "元");
        newchar = newchar.replace("零角", "");
        newchar = newchar.replace("零分", "");
        if (newchar.charAt(newchar.length - 1) == "元") {
            newchar = newchar + "整"
        }
        return newchar;
    },

    /********************************************* String 字符串操作 ******************************/

    /**
     * 去除空格
     * @param  {str}
     * @param  {type} 
     * type:  1-所有空格  2-前后空格  3-前空格 4-后空格
     * @return {String}
     */
    trim: function (str, type) {
        type = type || 1
        switch (type) {
            case 1:
                return str.replace(/\s+/g, "");
            case 2:
                return str.replace(/(^\s*)|(\s*$)/g, "");
            case 3:
                return str.replace(/(^\s*)/g, "");
            case 4:
                return str.replace(/(\s*$)/g, "");
            default:
                return str;
        }
    },

    /*
    *  检测密码强度
    */
    checkPwd: function (str) {
        var Lv = 0;
        if (str.length < 6) {
            return Lv
        }
        if (/[0-9]/.test(str)) {
            Lv++
        }
        if (/[a-z]/.test(str)) {
            Lv++
        }
        if (/[A-Z]/.test(str)) {
            Lv++
        }
        if (/[\.|-|_]/.test(str)) {
            Lv++
        }
        return Lv;
    },

    /**
    * 函数节流器
    * @param  {Function} fn 需要执行性的函数
    * @param  {number} time 时间戳
    * @param  {number} interval 间隔时间
    */
    debouncer: function (fn, time, interval = 200) {
        if (time - (window.debounceTimestamp || 0) > interval) {
            fn && fn();
            window.debounceTimestamp = time;
        }
    },

    /**
     * 在字符串中插入新字符串
     * @param {string} soure 源字符
     * @param {string} index 插入字符的位置
     * @param {string} newStr 需要插入的字符
     * @returns {string} 返回新生成的字符
     */
    insertStr: function (soure, index, newStr) {
        var str = soure.slice(0, index) + newStr + soure.slice(index);
        return str;
    },

    /**
     * 判断两个对象是否键值相同
     * @param  {Object}  a 第一个对象
     * @param  {Object}  b 第一个对象
     * @return {Boolean}   相同返回true，否则返回false
     */
    isObjectEqual: function (a, b) {
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        if (aProps.length !== bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    },

    /**
     * 16进制颜色转RGB\RGBA字符串
     * @param  {String} val 16进制颜色值
     * @param  {Number} opa 不透明度，取值0~1
     * @return {String}     转换后的RGB或RGBA颜色值
     */
    colorToRGB: function (val, opa) {
        var pattern = /^(#?)[a-fA-F0-9]{6}$/; //16进制颜色值校验规则
        var isOpa = typeof opa == 'number'; //判断是否有设置不透明度

        if (!pattern.test(val)) { //如果值不符合规则返回空字符
            return '';
        }

        var v = val.replace(/#/, ''); //如果有#号先去除#号
        var rgbArr = [];
        var rgbStr = '';

        for (var i = 0; i < 3; i++) {
            var item = v.substring(i * 2, i * 2 + 2);
            var num = parseInt(item, 16);
            rgbArr.push(num);
        }

        rgbStr = rgbArr.join();
        rgbStr = 'rgb' + (isOpa ? 'a' : '') + '(' + rgbStr + (isOpa ? ',' + opa : '') + ')';
        return rgbStr;

    },

    /**
     * 追加url参数
     * @param {string} url url参数
     * @param {string|object} key 名字或者对象
     * @param {string} value 值
     * @return {string} 返回新的url
     * @example
     * appendQuery('lechebang.com', 'id', 3);
     * appendQuery('lechebang.com?key=value', { cityId: 2, cityName: '北京'});
     */
    appendQuery: function (url, key, value) {
        var options = key;
        if (typeof options == 'string') {
            options = {};
            options[key] = value;
        }
        options = $.param(options);
        if (url.includes('?')) {
            url += '&' + options
        } else {
            url += '?' + options
        }
        return url;
    },

    /**
     * 判断a数组是否包含b数组中
     */
    getArrRepeat: function (arr1, arr2) {
        return arr1.filter((item, index) => {
            return arr2.includes(item)
        })
    }
}


Vue.prototype.$utils = utils;

export default {
    utils
};

